import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Main from './layouts/Main'; // fallback for lazy pages
import './static/css/main.scss'; // All of our styles

const { PUBLIC_URL } = process.env;

// Every route - we lazy load so that each page can be chunked
// NOTE that some of these chunks are very small. We should optimize
// which pages are lazy loaded in the future.
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const Index = lazy(() => import('./pages/Index'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Projects = lazy(() => import('./pages/Projects'));
const Resume = lazy(() => import('./pages/Resume'));
const Stats = lazy(() => import('./pages/Stats'));
const ResumePdf = lazy(() => import('./pages/ResumePdf'));

// import * as amplitude from '@amplitude/analytics-browser';
// amplitude.init('3fb65f9457fd76125f3b92de4cf1d712');

const App = () => {
	return (
		<BrowserRouter basename={PUBLIC_URL}>
			{/* <AmplitudeTracker></AmplitudeTracker> */}
			<Suspense fallback={<Main />}>
				<Routes>
					<Route path="/" element={<Index />} />
					<Route path="/about" element={<About />} />
					<Route path="/projects" element={<Projects />} />
					<Route path="/stats" element={<Stats />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/resume" element={<Resume />} />
					<Route path="/resume/pdf" element={<ResumePdf />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
};

// const AmplitudeTracker = function () {
// 	const { pathname } = useLocation();
// 	useEffect(() => {
// 		amplitude.track(window.location.href);
// 	}, [pathname]);
// 	return <></>;
// };

export default App;
